import React from "react";
import SectionDivider from "../components/Employers/SectionDivider";
import SummaryBlock from "../components/Home/SummaryBlock";
import axios from "axios";
import { useEffect, useState } from "react";
import Layout from "../components/layout";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Testimonial from "../components/Employers/Testimonial";
import LogoSection from "../components/Home/LogoSection";
import FlatButton from "../components/Reusables/FlatButton";
import styled from "styled-components";
import { Link } from "gatsby";
import UniversityStudents from "../components/Students/UniversityStudents";
import HoverEffectBlocks from "../components/About/HoverEffectBlock";
import EligibilityAndBenefits from "../components/Students/EligibilityAndBenefits";
import Accordion from "../components/Reusables/Accordion";
import StatisticBox from "../components/Home/StatisticBlock";
import logo48 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/47.png";
import logo49 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/48.png";
import logo50 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/49.png";
import logo51 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/50.png";
import logo52 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/51.png";
import logo53 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/52.png";
import logo54 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/53.png";
import logo55 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/54.png";
import logo56 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/55.png";
import logo67 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/66.png";
import logo68 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/67.png";
import logo69 from "../images/UpReachPartnerLogos/PartnerUniversityLogo/68.png";
import testimonial3 from "../images/pageImages/Universities/Testimonial3.jpg";
import testimonial1 from "../images/pageImages/Universities/Testimonial1.jpg";
import testimonial2 from "../images/pageImages/Universities/Testimonial2.jpg";
import university from "../images/pageImages/Universities/university.jpg";
import logo0 from "../images/UpReachPartnerLogos/OtherPartnerLogos/0.png";
import logo7 from "../images/UpReachPartnerLogos/OtherPartnerLogos/7.png";
import logo12 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/12.png";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .boxHeight {
    height: 600px;
    @media (max-width: 600px) {
      height: 350px;
    }
  }
`;

const body = (
  <div>
    <p>
      Strong partnerships with universities are crucial to our success.
      upReach’s intensive and personalised programmes of support help
      universities achieve their progression goals while simultaneously
      increasing the proportion of disadvantaged students that secure graduate
      jobs.
    </p>
    <p>
      Our expertise helps increase engagement, close the employability skills
      gap and boost graduate outcomes. Our students study at over 70 different
      universities across the UK, and we work closely with WP teams, Faculties,
      Student Societies, Student Unions and Careers Services.
    </p>
  </div>
);

const summary = [
  {
    title: "Universities",
    description: body,
    image: `${university}`,
    cta: "Get in touch",
    href: "Liberty.ohagan@upreach.org.uk",
    hrefEmail: true,
  },
];

const carousel = [
  {
    description:
      "The University of Exeter is delighted to be a partner University and support the excellent work that upReach offers our students in supporting their career plans. The upReach team are passionate about what they do, and the range of help and activities enables students to realise their career goals and aspirations through engagement with employer partners and support from mentors to meet their individual needs. Truly inspiring stories from upReach alumni Associates!",
    image: `${testimonial1}`,
    author: "- Career Consultant, University of Exeter",
  },
  {
    description:
      "Initiatives such as upReach show what can be done. They’ve recently launched a new range of Assessed Virtual Internships that have delivered opportunities for over 1,000 disadvantaged students. They provide training in areas from communication skills to coding, with top performers recommended for graduate positions at leading firms such as Deloitte and McKinsey. It shows what really can be done with technology. We always need to remember why we are doing this – we are doing this to level the playing field by creating opportunities for those who for too long haven’t had those chances.",
    image: `${testimonial2}`,
    author: "-  Michelle Donelan, Universities Minister, July 2020",
  },
  {
    description:
      "My Programme Coordinator changed everything for me. I can honestly say that joining upReach was the best decision I have made. Coming from a low socioeconomic background as well as being a mature student and living with a disability, I didn't have much hope in terms of employability. I can hand on heart say that upReach has changed my life by enabling me to believe that I deserve a top graduate job. I no longer feel ashamed of my background, I now know to use it to my advantage because skills that have arisen from life experience can be invaluable to many employers.",
    image: `${testimonial3}`,
    author: "- Lancaster Rise Associate, June 2020",
  },
];

const statistic = [
  {
    numbers: 86.5,
    description:
      "of upReach Associates who graduated in 2018 were in professional employment or further study 15 months after graduation.",
    color: "rgb(70, 79, 208)",
    suffix: "%",
    decimals: 1,
  },
  {
    numbers: 28000,
    description:
      "median salary of our 2018 graduates who secured highly skilled employment, £4,000 higher than the average university graduate.",
    color: "rgb(48, 69, 99)",
    prefix: "£",
  },
  {
    numbers: 505,
    description:
      "industry mentors were paired with upReach Associates in 2018/19.",
    color: "rgb(93, 185, 219)",
  },
  {
    numbers: 1000,
    description:
      "attendances at Insight days, Career Academies and Skills Workshops in 2018/19.",
    color: "#FF8600",
  },
  {
    numbers: 140,
    description: "Associates participated in exclusive opportunities in 2018. ",
    color: "rgb(70, 79, 208)",
  },
  {
    description:
      "upReach Associates at the University of Exeter not only had a graduate destination score higher than bursary eligible students from the university, but also outperformed their more advantaged peers.",
    color: "rgb(48, 69, 99)",
  },
];

const partnerHeading = {
  title: "OUR UNIVERSITY PARTNERS INCLUDE",
  description:
    "Our students study at over 70 different universities across the UK, and we work in collaboration with 11 Rise university partners. If you would like to discuss how you could partner with upReach, please email liberty.ohagan@upreach.org.uk.",
};
const partnerUniHeading = {
  title: "OTHER RISE PROGRAMME FUNDERS INCLUDE",
  description:
    "upReach are grateful to the following providers who also fund our Rise programme of support in addition to our university partners.",
};

const logos = [
  { logo: `${logo49}` },
  { logo: `${logo67}` },
  { logo: `${logo50}` },
  { logo: `${logo51}` },
  { logo: `${logo68}` },
  { logo: `${logo52}` },
  { logo: `${logo53}` },
  { logo: `${logo54}` },
  { logo: `${logo69}` },
  { logo: `${logo56}` },
];

const funderLogos = [
  { logo: `${logo7}` },
  { logo: `${logo12}` },
  { logo: `${logo0}` },
];

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Universities />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/university-page`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};
const fetchStats = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stats`);
};

const Universities = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const { data: pageData, isLoading } = useQuery("universities", fetchData);
  const { data: heroImage } = useQuery("universityImages", fetchHeroImage);
  const { data: stats } = useQuery("universityStats", fetchStats);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
    if (stats !== undefined) {
      setStatsData(stats.data);
    }
  }, [pageData, heroImage, stats]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showBanner &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "UniversitiesPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}
        {newData && newData.showSummary && newData.Summary && (
          <SummaryBlock summary={newData.Summary} />
        )}
        {newData &&
          newData.showStatistics &&
          statsData &&
          statsData.length > 0 &&
          statsData.map(
            item =>
              item.slug.toLowerCase() === "Universities Page".toLowerCase() &&
              item.StudentStatistics.length > 0 && (
                <StatisticBox statistic={item.StudentStatistics} />
              )
          )}
        {/* <SectionDivider /> */}
        {newData && newData.showCarousel && newData.Carousel.length > 0 && (
          <Testimonial carousel={newData.Carousel} />
        )}
        {newData && newData.showLogos && (
          <LogoSection
            logos={newData.LogoNew.logos}
            logoSectionHeading={newData.LogoNew}
          />
        )}
        {/* <LogoSection logos={pageData.funderLogo.logos} logoSectionHeading={pageData.funderLogo} /> */}
      </StyledWrapper>
    </Layout>
  );
};
